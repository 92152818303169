import _ from 'lodash';
import i18n from 'i18next';
import APISettings from 'src/API/APISettings';
import { randomColor } from 'randomcolor';
import { getOptionsFunct } from 'src/Board/Workspace/availableWidgets';
import { AVAILABLE_LANGUAGES } from 'src/consts';
import config from 'src/config/config';
import APISettingsAcademy from 'src/API/APISettingsAcademy';
import { dynamicConfig } from 'src/config/dynamicConfig/dynamicConfig';

export function handleAsync(promise){
    return promise.then(data => (data))
    .catch(e => {
        window.onerror(e.message,undefined,undefined,undefined,e);
        throw e;
    });
}

export function objectToParamsString(obj) {
    const params = Object.keys(obj);
    if (params.length === 0) return "";    
    let rv = "";    
    _.map(params, (p) => {
        if (obj[p] !== undefined) {
            rv += `&${p}=${obj[p]}`;
        }
    });
    rv = rv.substr(1);
    return "?"+rv;
}
export function nestedObjectToQueryString(obj, prefix = '') {
    // TODO: test !!
    const queryParams = [];
  
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = prefix ? `${prefix}[${key}]` : key;
        let value = obj[key];
  
        if (value !== null && typeof value === 'object') {
          if (Array.isArray(value)) {
            // Handle arrays
            value.forEach((item, index) => {
              if (typeof item === 'object' && !Array.isArray(item)) {
                // Handle array of objects
                Object.keys(item).forEach(subKey => {
                  let arrayObjKey = `${propName}[${index}][${subKey}]`;
                  queryParams.push(`${encodeURIComponent(arrayObjKey)}=${encodeURIComponent(item[subKey])}`);
                });
              } else {
                // Handle simple array values
                queryParams.push(`${encodeURIComponent(propName)}[${index}]=${encodeURIComponent(item)}`);
              }
            });
          } else {
            // Recursively handle nested objects
            queryParams.push(nestedObjectToQueryString(value, propName));
          }
        } else {
          // Convert non-object values to query string format
          queryParams.push(`${encodeURIComponent(propName)}=${encodeURIComponent(value)}`);
        }
      }
    }
  
    return queryParams.join('&');
  }
export function calcDaysDifference(start, end) {
    let diff = 1;
    let date1 = new Date(start < end ? start : end);
    let date2 = new Date(start < end ? end : start);

    while (!isDateEqual(date1, date2)) {
        diff++;
        date1.setDate(date1.getDate() + 1);
    }

    return diff;
}

// simple DATE check (doesn't check TIME)
export function isDateEqual(start, end) {
    let isEqual = true;

    if (start.getFullYear() - end.getFullYear() !== 0) isEqual = false;
    if (start.getMonth() - end.getMonth() !== 0) isEqual = false;
    if (start.getDate() - end.getDate() !== 0) isEqual = false;

    return isEqual;
}

export function dateToString(date, mode) {
    if (Object.prototype.toString.call(date) !== '[object Date]') return date;
    // substr shenanigans so always double digits (01, 02, ..)
    switch (mode) {     
        default: return (
            date.getFullYear() +
            '-' +
            ('0' + (date.getMonth() + 1)).substr(-2) +
            '-' +
            ('0' + date.getDate()).substr(-2)
        );   
    }    
}

export function stringToDate(dateString, separator) {
    // converts date string YYYY-MM-DD (e.g. 2019-12-27) to Date object. -- do not use with other formats !
    if (separator === undefined) separator = '-';
    const d = dateString.split(separator);
    return new Date(d[0], d[1] - 1, d[2]);
}

export function dateToTimeString(date, withSeconds) {
    const rv = padWithZero(date.getHours()) + ':' + padWithZero(date.getMinutes());
    if (withSeconds) {
        return `${rv}:${padWithZero(date.getSeconds())}`;
    } else {
        return rv;
    }
}

// returns new date obj with the same date as the provided one but with time set to zero - can be used for easier date comparison
export function dateToNoTime(date){
    let rv = new Date(date);
    rv.setHours(0); rv.setMinutes(0); rv.setSeconds(0); rv.setMilliseconds(0);
    return rv;
}

// checks if given date object has same day-month-year as current system date
export function dateIsToday(dateToCheck) {    
    const now = new Date();
    if (
        now.getDate() === dateToCheck.getDate() &&
        now.getMonth() === dateToCheck.getMonth() &&
        now.getFullYear() === dateToCheck.getFullYear()
    ) {
        return true;
    } else {
        return false
    }
}
export function dateToFullStringIfRequired(date) {  // returns time or date + time (if current date is not equal to given date)
    let rv = '';
    if (!dateIsToday(date)) rv += dateToString(date) + ' ';
    rv += dateToTimeString(date);
    
    return rv;
}
export function timestampToDate(timestamp) {
    return new Date(timestamp * 1000);
}

export function dateToTimestamp(date) {
    return Date.parse(date) / 1000;
}

export function getDatesArrayFromRange(from, to) {
    let dates = [];
    let date = new Date(from);
    for (;;) {
        dates.push(_.cloneDeep(date));
        if (isDateEqual(date, to)) break;
        date.setDate(date.getDate() + 1);
    }
    return dates;
}

// e.g. converting from 914 to "09:14"
export function timeIntToString(timeInt) {
    if(timeInt === null || timeInt === undefined) return undefined;
    return `${ padWithZero( Math.floor(timeInt / 100) ) }:${ padWithZero(timeInt % 100) }`;
}

// e.g. converting from "09:14" to 914
export function timeStringToInt(timeString) {
    const tmp = timeString.split(':');
    return parseInt(tmp[0]) * 100 + parseInt(tmp[1]);
};

// converting from relative to absolute dates (in: (7,days), out:[DATE, CURRENT_DATE])
// export function dateToAbs(unit, number){
//     console.log(unit,number);
//     const to = new Date(Date.now());
//     let from = new Date(Date.now());
//     switch(unit){
//         case 'days':  from.setDate( from.getDate() - number ); break;
//         case 'weeks': from.setDate( from.getDate() - number * 7 ); break;
//         case 'months': from.setMonth( from.getMonth() - number ); break;
//         default: break;
//     }
//     console.log(from,to);
//     return [from,to];
// }


// convert Date obj. to another timezone, using timezone strings, e.g. 'Europe/Vienna'
export function convertTZ(date, tz) {
    // use carefully - in general we prefer to keep handling of timezones from frontend and handle these in backend instead
    return new Date(date.toLocaleString("en-US", {timeZone: tz}));
}

export function dateToSettings(o, init=true) { // convert Date objects to { _dataType: 'date', value: Date }
    if(init){
        o = _.cloneDeep(o);
    }
    for (let key in o) {
        if (o[key] !== null && typeof o[key] === "object") {
            dateToSettings(o[key], false);
        }
        if(o[key] instanceof Date){
            o[key] = {_dataType: 'date', value: o[key]};
        }
    }
    return o;
}
export function dateFromSettings(o, init=true) { // convert { _dataType: 'date', value: Date } to Date object
    if(init){
        o = _.cloneDeep(o);
    }
    for (let key in o) {
        if(o[key] !== null && o[key]._dataType === 'date' && o[key].value !== undefined){
            o[key] = new Date(o[key].value);
        }
        if (o[key] !== null && typeof o[key] === "object") {
            dateFromSettings(o[key], false);
        }
    }
    return o;
}
// creates new array with length len,
// using the existing arr for values as far as possible
// if len > arr.length, pads right side with 0s
export function trimArrayToLength(arr, len) {
    if (arr.length === len)
        return arr;
    
    let newArr = [];

    for (let i = 0; i < len; i++) {
        newArr.push(arr[i] ? arr[i] : 0);
    }

    return newArr;
}

// thanks to: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
// takes a string and returns hash number
export function stringToHash(str) {
    var hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function getSelectOptionsFromArr(arr) {
    return _.map(arr, e => {
        return { label: e, value: e };
    });
}

// finds matches at the end of query and the beginning of autocomplete
// the biggest match then gets replaced, thus autocompleting the word
export function completeWord(base, suggestion) {
    let c = -1;
    for (let i = 1; i <= Math.min(suggestion.length, base.length); i++) {
        const suggestionPart = suggestion.slice(0, i);
        const basePart = base.slice(-i);

        if (suggestionPart === basePart) c = i;
    }

    // invalid
    if (c === -1) return base;

    return base + suggestion.slice(c);
}

// //Pads numbers with a preceding 0 if the number is less than 10.
export function padWithZero(val){
    if (parseInt(val) < 10){
        return "0" + val;
    }
    return val;
}
export function toBase64(file) {    // get js File object with img and convert to base64 encoded image (which can e.g. be stored in db)
    console.log(file);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
};
// export function arrHasNext(arr, i, number) {
//     return (arr.length) - 1 >= (i + number) ? true : false;
// }
// export function arrGetNext(arr, i, number) {
//     return arr[i + number];
// }

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getWeekdays() {
    let days = [
        { id: 1, value: 'M', text: 'Mon' },
        { id: 2, value: 'T', text: 'Tue' },
        { id: 3, value: 'W', text: 'Wed' },
        { id: 4, value: 'T', text: 'Thu' },
        { id: 5, value: 'F', text: 'Fri' },
        { id: 6, value: 'S', text: 'Sat' },
        { id: 0, value: 'S', text: 'Sun' },
    ];
    return _.map(days, d => ({...d, text: i18n.t(d.text)}) );
}

export function downloadFile(data, filename, mime, bom) {
    var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
    if (mime === 'xlsx') { mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
    var blobURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
                
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    
    document.body.appendChild(tempLink);
    tempLink.click();
    
    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 0)
}

export function getWidgetState(workspaces, board, widgetId) {
    if (workspaces[board.currentWorkspaceIndex] !== undefined) {
        const widget = _.find(workspaces[board.currentWorkspaceIndex].widgets, { id: widgetId })
        if (widget) {
            return widget.state;
        }
    }
    return;
}

export async function createDefaultWorkspaces(defaultWorkspaces){
    for (let i = 0; i < defaultWorkspaces.length; i++) {
        let w = defaultWorkspaces[i];
        // this.props.postWorkspace(w);
        const widgetIds = await Promise.all(w.widgets.map(async (widget) => {
            const id = (await APISettings.postItem('widgets', widget)).data.id;
            return id;
        }))
        await APISettings.postItem('workspaces', {...w, widgets: widgetIds});
    }
}

export function getColors(seeds = [], numberOfColors, preferCI = true) {
    numberOfColors = numberOfColors ? numberOfColors : (seeds ? seeds.length : 0);
    const ciColors = window.rxStore.getState().theme.ci.replace(/\s/g, '').split(',');
    const ciColorsFont = window.rxStore.getState().theme.ciFont.replace(/\s/g, '').split(',');

    let rv = {};
    if (!preferCI) {
        seeds.map((seed) => {
            const color = randomColor({
                luminosity: 'bright',
                seed: seed,
            });
            rv[seed] = color;
            return undefined;
        });
    } else {
        seeds.map((seed, index) => {
            rv[seed] = ciColors[index]
                ? [ciColors[index], ciColorsFont[index] ? ciColorsFont[index] : '#000']
                : [randomColor({ luminosity: 'bright', seed: seed, }), '#000'];    // continue with random colors - if all predefined "CI-colors" are used already
            return undefined;
        })
    }
    return rv;
}

export function base64Decode(encodedStr) {
    // Create a buffer from the string
    let bufferObj = Buffer.from(encodedStr, "base64");
    // Encode the Buffer as a utf8 string
    return bufferObj.toString("utf8");
}

// export function base64Encode(string) {
//     // Create buffer object, specifying utf8 as encoding
//     let bufferObj = Buffer.from(string, "utf8");
//     // Encode the Buffer as a base64 string
//     return bufferObj.toString("base64");
// }

export function getUsername() {
    // const credentials = window.localStorage.getItem('credentials') ? window.localStorage.getItem('credentials') : undefined;
    // if (credentials) {
    //     let str = base64Decode(credentials);
    //     str = str.split(':');
    //     return str[0];
    // } else {
    if(window.rxStore){
        const redux = window.rxStore.getState();
        if (redux.me) {
            return redux.me.username;
        } else {
            return null;
        }
    }
    // }
}

// export function getStrMaxLength(str, numberOfCharacters) {
//     return str.slice(0, numberOfCharacters).replace(/\s[^\s]*$/, '');
// }

export function getUniqueWidgetsSettings(widgets, availableWidgets) {  // get unique list of all settings that are used by the provided widgets
    let settings = {};
    if (widgets) {
        widgets.map((w) => {
            const widget = _.find(availableWidgets, { key: w.type });
            if (widget && widget.props && widget.props.settings) {
                Object.keys(widget.props.settings).map(s => {
                    if(widget.props.settings[s].globalSetting !== false){
                        settings[s] = widget.props.settings[s];
                    }
                    return undefined;
                });
            }
            return undefined;
        });
    }
    return settings;
}

export function getLastX(o){
    const units = [
        { label: i18n.t('days'), value: 'days' },
        { label: i18n.t('weeks'), value: 'weeks' },
        { label: i18n.t('months'), value: 'months' },
    ];

    return o.unit 
        ? { ...o, unit: _.find(units, { value: o.unit } )}
        : undefined;
}

// provide width or height for _out and get the corresponding value to keep the ratio
export function scale(_in, _out){
    let rv;
    if(_out.w !== undefined && _out.h === undefined){ 
        rv = {
            w: _out.w,
            h: _in.h / ( _in.w / _out.w ),
        };
    }
    if(_out.w === undefined && _out.h !== undefined){
        rv = {
            w: _in.w / ( _in.h / _out.h ),
            h: _out.h,
        };
    }
    return rv;
}

// scale using _out width and height as max values while keeping ratio
export function scaleMax(_in, _out){
    let rv;
    if(_out.w === undefined || _out.h === undefined) return;

    if( _in.h > _in.w ){
        rv = scale(_in, {h: _out.h});
        if(rv.w >_out.w) rv = scale(rv,{ w :_out.w });
    } else {
        rv = scale(_in, {w: _out.w});
        if(rv.h >_out.h) rv = scale(rv,{ h :_out.h });
    }
    return rv;
}

export function formatSettings(widgetSettings, currentSettings, options, config={
    showDatesAbsolute: false
}){
    let rv = {};
    if(currentSettings){
        Object.keys(currentSettings).map((s,i) => {
            if(!widgetSettings[s]){ return null; }
            let val;
            switch(s){
                case 'date':    // future TODO: get rid of this (e.g. by saving type to currentSettings backend ?)
                    const renderAbs = (from, to) => `${dateToString(from)} to ${dateToString(to)}`
                    if(currentSettings[s].length === 2){
                        val = renderAbs(new Date(currentSettings[s][0]), new Date(currentSettings[s][1]));
                    } else if(currentSettings[s].unit && currentSettings[s].number) {
                        if(config.showDatesAbsolute) {
                            val = renderAbs(currentSettings[s].display_from, currentSettings[s].display_to);
                        } else {
                            val = `${currentSettings[s].number} ${i18n.t(currentSettings[s].unit.value)}`;  // TODO: do not save unit.label to backend at all? - we dont use it anymore as its not usable for translation reasons anyways
                        }
                    }
                    break;
                default:
                    val = currentSettings[s].map(
                        (id,index) => {
                            if(options[s]){
                                const option = getOptionById(options[s],id);
                                if(option){
                                    return `${option.label}`
                                } else {
                                    // option (e.g. profile / profile group) not existing anymore - probably due to deletion
                                }
                            }
                            return undefined;
                        }
                    )
                    val = val.filter(e=>e!==undefined);
            }
            rv[s] = {title: widgetSettings[s].title, data: val};
            return undefined;
        });
        return rv;
    }
}

export async function getOptions(settings){
    let options = {};
    await Promise.all(Object.keys(settings).map(async (s)=> {
        const opts = await getOptionsFunct(s)();
        if(opts) options[s] = opts;
    }));
    return options;
}
export function getOptionById(options,id){
    // TODO: reuse this in WidgetSettings.js:render() (as something similar is used there) ?
    if (options[0] && options[0].options) {    // handling grouped dropdown
        for (let i = 0; i < options.length; i++){
            const o = options[i];
            let child = _.find(o.options, { value: id });
            if (child) return child;
        }
    } else {    // handling ungrouped dropdown
        return _.find(options, { value: id });
    }
}

// returns a new string (based on provided string while replacing the character at the provided index )
export function replaceAt(string, index, character){
    return string.substring(0,index) + character + string.substring(index+1);
}

// export function isV2(){
//     return window.location.pathname.match(/^\/v2\/.*/);
// }

export function getBrowserLanguage() {
    let userLanguage;
    window.navigator.languages.some((browserLang) => {
        browserLang = browserLang.replace(/-\w*/, '');
        const isSupported = AVAILABLE_LANGUAGES.includes(browserLang);
        if (isSupported) {
            userLanguage = browserLang;
        }
        return isSupported;
    });
    return userLanguage ? userLanguage : 'en';
}

export function cacheBust(url) {
    if (['development'].includes(process.env.REACT_APP_ENV)) {
        const regex = new RegExp(`^(${process.env.PUBLIC_URL})`,'g');
        url = url.replace(regex, '$1/cache-bust');
        // TODO improvement: do this in development env. too (this would require to somehow enable local dev. mode to
        // generate files which contain the ".REACT_APP_VERSION" (currently generated with build_unattended.sh which is not executed in local dev. env. ?)
    } else {
        url = url.replace(/\.([^\/]*)$/g, `.${process.env.REACT_APP_VERSION}.$1`)
    }
    return url;
}

export function hasContent(string){
    return typeof string === 'string' && string !== ''
}

export function tryProfileLegacy(query, cgid) {
    let url = '/cgi-bin/feed/query';
    let params = {
        outputType: 'html',
        action: 'generate',
        words: encodeURIComponent(query),
        cgid,
    };
    window.open(url + objectToParamsString(params), '_blank');
}

export function getLastQueryWord(word) {
    let out;

    out = word.substring(word.lastIndexOf(' ') + 1);
    out = out.substring(out.lastIndexOf('(') + 1);
    out = out.substring(out.lastIndexOf(')') + 1);
    out = out.substring(out.lastIndexOf('+') + 1);
    out = out.substring(out.lastIndexOf('-') + 1);
    out = out.substring(out.lastIndexOf('%') + 1);

    return out;
}

export function getResponseHeader(response, header){
    return response?.headers?.get(header) || null;
}

export function getCookie(cname) {	// source: https://www.w3schools.com/js/js_cookies.asp
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return undefined;
}

export function deleteCookie(cname) {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function trackEvent(matomo, eventCategory, eventAction, data) {
    if (data) {
        data = Object.keys(data).map((key) => ` ${key}: "${data[key]}"`);
    }
    // eventAction = `${eventAction}${data ? `:${data}` : ''}`;
    matomo?.push(['trackEvent', eventCategory, eventAction, data]);
    // see https://matomo.org/faq/reports/implement-event-tracking-with-matomo/
}

// changes value to lower case if value is of type string
export function toLowerCase(value) {
    return typeof value === 'string' ? value.toLowerCase() : value;
}

export function getSortFunct(sortKey) {
    return (a, b) => toLowerCase(a[sortKey]) > toLowerCase(b[sortKey]) && 1 || -1;
}

export function isRelativeUrl(str) {
    var regexAbsUrl = new RegExp('^(?:[a-z]+:)?//', 'i');
    return !regexAbsUrl.test(str);
}

export function urlParamsToObj(searchParamsString, acceptedParams=[]) {    // string to obj e.g.: "?a=1&b=2" --> {a:1, b:2}
    let res = {};
    const params = new URLSearchParams(searchParamsString);
    params.forEach((val, key) => {
        if(acceptedParams.length === 0 || acceptedParams.includes(key)){
            res[key] = decodeURIComponent(val)
        }
    });
    return res;
}

export function appendQueryString(url, queryString) {
    // Check if the URL already contains a query string
    if (url.includes('?')) {
      // If URL already has parameters, append with '&'
      return `${url}&${queryString}`;
    } else {
      // If no parameters, append with '?'
      return `${url}?${queryString}`;
    }
}

export function redirectToUrl(url, allowAbsRedirect=config.allowAbsRedirect) {
    
    // if url starts with origin, we can remove origin, to make it become "relative"
    const re = new RegExp(`^${window.location.origin}`, 'g');
    url = url.replace(re, '');

    const isRelative = isRelativeUrl(url);
    if (url && (isRelative || allowAbsRedirect)) {
        window.location.href = isRelative ? `${window.location.origin}${url}` : url;
        return true;
    } else return false;
}

export function areDatesEqual(a, b) {
    return(
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate()
    )
}

export function formatGoogleAuthHeader(token) {
    return `Bearer ${token}`;
}

export function getAPISettingsType() {
    const rxStore = window.rxStore.getState();
    if (rxStore.tour.show) return dynamicConfig.APISettingsTour;
    else if (rxStore.board.showAcademy) return APISettingsAcademy;
    else return APISettings;
}

// subst and returns new date object
export function substrDaysFromDate(date, substrNumberOfDays) {
    let d = _.cloneDeep(date);
    d.setDate(d.getDate() - substrNumberOfDays);
    return d;
}

// add and returns new date object
export function addDaysToDate(date, substrNumberOfDays) {
    let d = _.cloneDeep(date);
    d.setDate(d.getDate() + substrNumberOfDays);
    return d;
}

// returns arr like ['2023-04-17','2023-04-18',...]
export function getDateRangeArrFormated(dateFrom, dateTo) {
    console.log(dateFrom, dateTo)
    let dateArr = [];
    let currDate = new Date(dateFrom);
    while (currDate <= dateTo) {
        dateArr.push(currDate);
        currDate = addDaysToDate(currDate, 1);
    }
    dateArr = dateArr.map(d => dateToString(d));
    return dateArr;
}

export function sanitizeQuery(queryString){
    // replace newlines (\n) in query with space (\n not allowed e.g. in /cgi-bin/feed/query?...words= call)
    return queryString?.replace(/\n/g, ' ');
}

// prevent "param=" and "param=undefined" (by removing keys with value '' or undefined from object)
export function sanitizeUrlParamsObj(urlParamsObject) {
    let keysForDel = [];
    Object.keys(urlParamsObject).forEach((key) => {
        const value = urlParamsObject[key];
    if (value === '' || value === undefined) {
        keysForDel.push(key);
    }
    });

    keysForDel.forEach(key => {
        delete urlParamsObject[key];
    });
    return urlParamsObject;
}

// always returns 'domain' e.g. 'example.com' (even if it starts with hostname e.g. 'some.example.com')
export function getCurrentDomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length > 2) {
        // If the hostname has subdomains, return the last two parts
        return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    } else {
        // If no subdomains, return the hostname
        return hostname;
    }
}

export function isMentionFindr() {
    return ['mentionfindr.emmo', 'mentionfindr.local', 'mentionfindr.com'].includes(getCurrentDomain());
}

export function getHashParams(allowedParamsArr) { return urlParamsToObj(window.location?.hash?.replace(/^[^?]*/, ''), allowedParamsArr) };

export const setFavicon = ({href, rel, size}) => {
    const link = document.querySelector(`link[rel*='${rel}'][sizes='${size}']`);
  
    if (link) {
      link.href = href;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = href;
      document.head.appendChild(newLink);
    }
};

export const addRangeToSet = (from, to, originalSet) => {
    // Create a new set from the original set to avoid modifying the original set
    const newSet = new Set(originalSet);

    // Add each number in the range to the new set
    for (let i = from; i <= to; i++) {
        newSet.add(i);
    }

    // Return the new set
    return newSet;
}

// receives https://www.example.com:8080/path/to/resource?query=123#section
// returns /path/to/resource?query=123#section
export function removeOrigin(url) {
    const parsedUrl = new URL(url); // Create a URL object
    return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash; // Return the path, query, and hash
  }

// export const splitVersionNumber = (versionNumber) => {
//     versionNumber = Number(versionNumber);  // ensure we get a number

//     // separate into major and minor version
//     return {
//         major: Math.floor(versionNumber / 100),
//         minor: versionNumber % 100,
//     }
// }

// export const isVersionCompatible = (currentVersion, expectedVersion) => {
//     return (
//         currentVersion.major === expectedVersion.major &&    // major versions need to match
//         currentVersion.minor >= expectedVersion.minor         // served minor versions need be equal or higher than required by frontend
//     );
// }