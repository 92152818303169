import React, { useCallback, useEffect, useState } from 'react';
import APIClips from 'src/API/APIClips';
import { dateToString } from 'src/Widgets/common/helpers';
import { MentionsVisualizerTypes } from '../MentionsVisualizer.types';
import APIProfileGroups from 'src/API/APIProfileGroups';
import config from 'src/config/config';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import APIProfiles from 'src/API/APIProfiles';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';

const useFetchClips = (
    selectedDateRange: MentionsVisualizerTypes.DateRange,
    searchGroupExists: boolean,
    searchProfileGroup: MentionsVisualizerTypes.ProfileGroupNative | null | undefined,
    selectedChannelGroup?: MentionsVisualizerTypes.ChannelGroupValue | undefined,
    isToggleChannels?: boolean,
    isEditorial?: boolean,
    isSearch?: boolean,
    tryQueryChannelGroup?: any,
    currentProfileGroup?: MentionsVisualizerTypes.ProfileGroupNative | null | undefined
) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MentionsVisualizerTypes.Clip[]>([]);

    const selectedChannels = useAppSelector((state) => state.channelGroupsWidgetReducer.selectedChannelIds);

    const dispatch = useAppDispatch();
    const channelGroups = useAppSelector((state) => state.channelGroupReducers) || [];

    const findChannelExistsById = (channelId: number) => {
        //find selectedChannelGroup in channelGroups by id and check if contains channelId
        return channelGroups.filter((channelGroup: ChannelGroup) => {
            return (
                channelGroup.id === (selectedChannelGroup?.value ?? tryQueryChannelGroup.id) &&
                channelGroup.channels.find((channel) => channel.id === channelId)
            );
        });

    };

    const filterFetchedClips = (clips: MentionsVisualizerTypes.Clip[]) => {
        if(selectedChannels && selectedChannels.length > 0 && !isToggleChannels) {
            const filteredClips = clips.filter((clip) => {
                return selectedChannels.includes(clip.channelNumber);
            });
            return filteredClips;
        }else if ((selectedChannelGroup || tryQueryChannelGroup) && channelGroups.length > 0 && isToggleChannels) {
            const filteredClips = clips.filter((clip) => {
                const channelExists = findChannelExistsById(clip.channelNumber);
                return channelExists.length > 0;
            });
            return filteredClips;
        }else {
            // If no channelGroups or selectedChannelGroup, return all clips
            return clips;
        }
    }

    const fetchClips = async (profileIds: string[], dateRange = selectedDateRange) => {
        try {
            !loading && setLoading(true);
    
            // if (isEditorial) {
            //     // Combine profile IDs into a single string
            //     const profileIdString = profileIds.join(',');
            //     const editorialClips = await APIClips.getEditorialClips(
            //         profileIdString,
            //         dateToString(dateRange.startDate),
            //         dateToString(dateRange.endDate)
            //     );
            //     const allClips = editorialClips.data;
            //     const clipsToShow = filterFetchedClips(allClips);
            //     setData(clipsToShow);
            //     setTimeout(() => {
            //         setLoading(false);
            //     }, 500);
            // } else {
                // Make individual calls for each profileId
                const clipPromises = profileIds.map((profileId) =>
                    // isEditorial ? APIClips.getEditorialClips(
                    //     profileId,
                    //     dateToString(dateRange.startDate),
                    //     dateToString(dateRange.endDate)
                    // ) : 
                    APIClips.getClips(
                        profileId,
                        dateToString(dateRange.startDate),
                        dateToString(dateRange.endDate)
                    )
                );
                const clipResponses = await Promise.all(clipPromises);
                const allClips = clipResponses.flatMap((res) => res.data);
                const clipsToShow = filterFetchedClips(allClips);
                setData(clipsToShow);
                if(!isEditorial && currentProfileGroup){
                    setLoading(false)
                }else if(isEditorial){
                    setLoading(false)
                }
        } catch (error) {
            console.error('Error fetching clips:', error);
        }
    };

    const fetchEditedClips = async (profileIds: string[]) => {
        try {
            !loading && setLoading(true);
            const clipPromises = profileIds.map((profileId) =>
                APIClips.getEditedClips(
                    profileId,
                    dateToString(selectedDateRange.startDate),
                    dateToString(selectedDateRange.endDate)
                )
            );
            const clipResponses = await Promise.all(clipPromises);
            const allClips = clipResponses.flatMap((res) => res.data);
            const clipsToShow = filterFetchedClips(allClips);
            setData(clipsToShow.filter((clip) => {
                return Object.keys(clip).length > 0;
            }));
            if(currentProfileGroup){
                setLoading(false)
            }else if(data.length){
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching clips:', error);
        } 
    };

    const fetchSearchQueryClips = useCallback(
        async (searchQuery: string, selectedDateRange: MentionsVisualizerTypes.DateRange) => {
            let res;

            try {
                !loading && setLoading(true);
                                if (!searchGroupExists) {
                    res = await APIProfileGroups.post({
                        title: config.searchQueryGroupTitle
                    });
                } else {
                    res = searchProfileGroup;
                }

                if ((res && res.rc === 0) || searchGroupExists) {
                    const searchProfileToCreate = {
                        title: searchQuery,
                        queries: {
                            '**': {
                                standardQuery: {
                                    query: searchQuery
                                },
                                superQuery: []
                            }
                        },
                        isSuper: false,
                        isEnabled: true,
                        isSearchAgent: true,
                        notifyIM: false,
                        notifyEmail: 0,
                        profileGroupId: res.id,
                        channelGroupId: null,
                        shareGroups: [],
                        updatedShareGroups: [],
                        logos: [],
                        initShareGroups: [],
                        hasQuery: true
                    };

                    // Check if the search query already exists in profiles only if the search group exists
                    const existingProfile : any =
                        searchProfileGroup &&
                        searchProfileGroup.profiles.find(
                            (profile: MentionsVisualizerTypes.ProfileTypes) => profile.title === searchQuery
                        );

                    if (existingProfile) {
                        // Profile exists, fetch clips
                        const searchCall = await APIClips.getSearchQueryClips(
                            existingProfile.id,
                            dateToString(selectedDateRange.startDate),
                            dateToString(selectedDateRange.endDate)
                        );
                        if (searchCall.rc === 0) {
                            fetchClips([existingProfile.id + 't'], selectedDateRange);
                            dispatch(getProfileGroups());
                        }
                    } else {
                        if (
                            searchProfileGroup &&
                            searchProfileGroup.profiles.length >= 10
                        ) {
                            // Update the first profile if there are already 10 profiles
                            const firstProfile : any = searchProfileGroup.profiles[0 as any];
                            const firstProfileDetails = await APIProfiles.get(
                                firstProfile.id
                            );
                            if (firstProfileDetails.rc === 0) {
                                const queryId =
                                    firstProfileDetails.data.queries['**'].standardQuery
                                        .id;
                                const updateRes = await APIProfiles.put(firstProfile.id, {
                                    ...searchProfileToCreate,
                                    queries: {
                                        '**': {
                                            standardQuery: {
                                                id: queryId,
                                                query: searchQuery
                                            },
                                            superQuery: []
                                        }
                                    },
                                    id: firstProfile.id
                                });
                                if (updateRes.rc === 0) {
                                    dispatch(getProfileGroups());
                                    const searchCall = await APIClips.getSearchQueryClips(
                                        firstProfile.id,
                                        dateToString(selectedDateRange.startDate),
                                        dateToString(selectedDateRange.endDate)
                                    );
                                    if (searchCall.rc === 0) {
                                        fetchClips([firstProfile.id + 't'], selectedDateRange);
                                    }
                                }
                            }
                        } else {
                            // Create a new profile if there are less than 10 profiles
                            const createRes = await APIProfiles.post(
                                searchProfileToCreate
                            );
                            if (createRes.rc === 0) {
                                dispatch(getProfileGroups());
                                const searchCall = await APIClips.getSearchQueryClips(
                                    createRes.data.id,
                                    dateToString(selectedDateRange.startDate),
                                    dateToString(selectedDateRange.endDate)
                                );
                                if (searchCall.rc === 0) {
                                    fetchClips([createRes.data.id + 't'], selectedDateRange);
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching clips:', error);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }
        },
        [searchGroupExists, searchProfileGroup, selectedDateRange, selectedChannelGroup, dispatch, selectedChannels, isToggleChannels]
    );

    useEffect(() => {
        !isSearch && setLoading(true)
    }, [])

    return { loading, data, fetchClips, fetchEditedClips, fetchSearchQueryClips };
};

export default useFetchClips;
